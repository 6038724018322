export const SEND_SIGNAL_CHECK_REQUEST = 'SEND_SIGNAL_CHECK_REQUEST';
export const SEND_BASELINE_STREAM_REQUEST = 'SEND_BASELINE_STREAM_REQUEST';
export const STOP_STREAM_REQUEST = 'STOP_STREAM_REQUEST';
export const SEND_CALIBRATE_BASELINE_REQUEST = 'SEND_CALIBRATE_BASELINE_REQUEST';
export const SEND_BINORAL_EEG_STREAM_REQUEST = 'SEND_BINORAL_EEG_STREAM_REQUEST';
export const SEND_CANCEL_STREAM_REQUEST = 'SEND_CANCEL_STREAM_REQUEST';
export const SEND_SIMBA_STREAMING_REQUEST = 'SEND_SIMBA_STREAMING_REQUEST';
export const SEND_MIND_MIRROR_STREAM_REQUEST = 'SEND_MIND_MIRROR_STREAM_REQUEST';
export const STOP_MIND_MIRROR_STREAM_REQUEST = 'STOP_MIND_MIRROR_STREAM_REQUEST';
export const START_VILISTUS_SIMBA_REQUEST = 'START_VILISTUS_SIMBA_REQUEST';
export const STOP_VILISTUS_STREAM_REQUEST = 'STOP_VILISTUS_STREAM_REQUEST';
export const SEND_VILSITUS_SIGNAL_REQUEST = 'SEND_VILSITUS_SIGNAL_REQUEST';