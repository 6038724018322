import React from 'react';
import { AnyAction } from "redux";
import { TherapyReport } from "../../../interfaces/reporting";
import * as actionType from '../../actions/actionTypes/reporting';
import { updateObj } from '../../utilities';



export interface ReportingState {
    therapyReport: TherapyReport | null;
    therapyReports: TherapyReport[]
}

let intialState: ReportingState = {
    therapyReport: null,
    therapyReports: []
}

const reporting = (
    state:  ReportingState = intialState, 
    action: AnyAction
): ReportingState => {
    switch(action.type) {
        case actionType.MAP_PAST_THERAPY_REPORT : return updateObj(state, {therapyReport: action.payload});
        case actionType.MAP_ALL_PAST_THERAPY_REPORTS : return mapTherapyReport(state, action.payload) 
        default:
            return state;
    }
}

const mapTherapyReport = (state: ReportingState, payload: any) => {
    console.log(payload);
  return updateObj(state, {therapyReports: payload});
}

export default reporting;