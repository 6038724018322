import React from "react";
import { AnyAction } from "redux";
import * as actionType from '../../actions/actionTypes/mindMirror';
import { updateObj } from '../../utilities';

import { UserForm } from "../../../interfaces/user";

export interface MindMirrorState {
    serialPort: any
    readerPort: any
    writerPort: any
    mindMirrorData: any,
}

const intialState = {
    serialPort: null,
    readerPort: null,
    writerPort: null,
    mindMirrorData: null,
}

const mindMirror = (
    state: MindMirrorState = intialState, 
    action: AnyAction
): MindMirrorState => {
    switch (action.type) {
        case actionType.MAP_MIND_MIRROR_VALUES: return updateObj(state, {mindMirrorData: action.payload});
        case actionType.SET_SERIAL_PORT: return updateObj(state, {serialPort: action.payload});
        case actionType.SET_WRITER_PORT: return updateObj(state, {writerPort: action.payload});
        case actionType.SET_READER_PORT: return updateObj(state, {readerPort: action.payload});
        default:
            return state;
    }
}




export default mindMirror;