import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Buttons = styled(Button)(({ theme }) => ({
    width: '100%',
    marginBottom: 5,
    background: theme.palette.primary.main,
}));

interface PrimaryButtonProps {
    children: React.ReactNode,
    clicked: Function
    disabled?: boolean
}

export default function PrimaryButton({
    children,
    clicked,
    disabled
}: PrimaryButtonProps) {
  return <Buttons onClick={() => clicked()} variant='contained' disabled={disabled ?? false}>{children}</Buttons>;
}
