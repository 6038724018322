import { AnyAction } from "redux";
import { ApiErrorMessage } from "../../../interfaces/error";
import * as actionType from '../../actions/actionTypes/errorTypes';
import { updateObj } from '../../utilities';

export interface ErrorReducerState {
    errorMessages: string[];
}

let intialState: ErrorReducerState = {
    errorMessages: []
}

const errorReducer = (
    state: ErrorReducerState = intialState, 
    action: AnyAction
): ErrorReducerState => {
    switch(action.type) {
        case actionType.ADD_ERROR_MESSAGE : return addErrorMessage(state, action.payload);
        case actionType.CONSOLE_LOG_REQUESTS : return consoleLogHttp(state, action.payload);
        default:
            return state;
    }
}


const addErrorMessage = (state: ErrorReducerState, payload: ApiErrorMessage) => {
    console.log('-------------- ERROR ------------');
    console.log(payload);
    console.log('-------------- ERROR ------------');

    let newErrorMessages = state.errorMessages.concat(`${payload.apiErrorMessage}`);

    return updateObj(state, {
        errorMessages: newErrorMessages
    });
}

const consoleLogHttp = (state: ErrorReducerState, payload: any) => {
    console.log(payload);
    return state
}

export default errorReducer;