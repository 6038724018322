import React from "react";
import { AnyAction } from "redux";
import * as actionType from '../../actions/actionTypes/activeTherapy';
import { updateObj } from '../../utilities';

import { BeforeTherapyData, AfterTherapyData } from "../../../interfaces/activeTherapy";
import { RootState } from "../../store";

export interface ActivTherapyState {
    loading: boolean,
    active: boolean,
    type: string 
    beforeTherapy: BeforeTherapyData
    afterTherapy: AfterTherapyData
}

let intialState:  ActivTherapyState = {
    loading: false,
    active: false,
    type: 'before',
    beforeTherapy: {
        beforeDate: '',
        beforeData: []
    },
    afterTherapy: {
        afterDate: '',
        afterData: []
    }
}

const activeTherapyReducer = (
    state:  ActivTherapyState = intialState, 
    action: AnyAction
):  ActivTherapyState => {
    switch(action.type) {
        case actionType.IS_THERAPY_ACTIVE : return updateObj(state, {loading: true});
        case actionType.MAP_THERAPY_ACTIVITY : return updateObj(state, {
            loading: false,
            active: action.payload.active
        });
        case actionType.GET_ACTIVE_THERAPY_DATA : return updateObj(state, {loading: true});
        case actionType.MAP_ACTIVE_THERAPY_DATA : return mapActiveTherapyData(state, action.payload)
        case actionType.RESET_ACTIVE_THERAPY : return updateObj(state, {
            loading: false,
            active: false,
            type: 'before',
            beforeTherapy: {
                beforeDate: '',
                beforeData: []
            },
            afterTherapy: {
                afterDate: '',
                afterData: []
            }
        });
        case actionType.SET_TYPE_OF_ACTIVE_THERAPY: return updateObj(state, {type: action.payload});
        case actionType.CREATE_AFTER_ACTIVE_THERAPY: return loggingFunction(state, action.payload);
        case actionType.CREATE_BEFORE_ACTIVE_THERAPY: return loggingFunction(state, action.payload);
        
        default:
            return state;
    }
}

const mapActiveTherapyData = (state: ActivTherapyState, payload: any) => {
    console.log('-------------------- MAP ACTIVE THERAPY DATA ------------------');
    console.log(payload);

    let before = {
        beforeDate: '',
        beforeData: []
    }
    if(payload.beforeTherapy !== null) {
        before.beforeData = payload.beforeTherapy.beforeData;
        before.beforeDate = payload.beforeTherapy.beforeDate;
    }

    let after = {
        afterDate: '',
        afterData: []
    }
    if(payload.afterTherapy !== null) {
        after.afterData = payload.afterTherapy.afterData;
        after.afterDate = payload.afterTherapy.afterDate;
    }



    console.log('-------------------- MAP ACTIVE THERAPY DATA ------------------');
    return updateObj(state, {
        beforeTherapy: before,
        afterTherapy: after
    });
}

const loggingFunction = (state: ActivTherapyState, payload: any) => {
    console.log('-------------------- LOGGING FUNCTION ------------------');
    console.log(payload);
    console.log('-------------------- LOGGING FUNCTION ------------------');
    return state;

}

export default activeTherapyReducer;