import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionType from "../actions/actionTypes/signalCheck";
import * as actionCreater from "../actions/actionCreaters/signalCheck";

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;

export const signalCheckWebsocket = (action$: any) =>
  action$.pipe(
    ofType(actionType.OPEN_SIGNAL_CHECK_WEBSOCKET),
    switchMap((action: any) => {
      console.log('ABOUT TO OPEN WEBOCKET');
      return Observable.create((obs: any) => {
        // var client = mqtt.connect(mqttWsUrl, { rejectUnauthorized: false });
        // client.on("connect", function () {
        //   client.subscribe(
        //     `/mindstreams/M1-${action.payload.uid}/signal-quality`,
        //     function (err) {
        //       console.log(err);
        //       if(!err) {
        //         console.log('Signal check mqtt connected')
        //       }
        //     }
        //   );
        // });

        // client.on("message", function (topic, message: any) {
        //   const data = JSON.parse(message);
        //   console.log(data);
        //   if (data) {
        //     if(data.EOF === "YES") {
        //       // client.end();
        //     } else if(data.signalCheck.length > 0) {
        //       obs.next(data);
        //     }
        //   }
        // }); 
      }).pipe(map((payload) => actionCreater.mapSignalCheckData(payload)));
    })
  );