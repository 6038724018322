import { Container } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import SimpleBottomNavigation from '../../components/navigationBar/bottomNavigationBar';
import { RootState } from '../../store/store';
import EmptyNav from '../../components/navigationBar/emptyNav';


type LayoutProps = {
    children: React.ReactNode
    authentication: boolean
    liveSession: boolean
}

export const Layout = (props:LayoutProps) => {
  let viewStatus = <EmptyNav/>;

  if(props.authentication && !props.liveSession) {
    viewStatus = <SimpleBottomNavigation/>;
  } else if(!props.authentication && !props.liveSession) {
    viewStatus = <></>;
  } else {
    viewStatus = <EmptyNav/>;
  }

  return (
      <>
        {viewStatus}
        <>
            {props.children}
        </>
      </>
  )
};

const mapStateToProps = (state: RootState) => ({
  authentication: state.userReducer.authenticated,
  liveSession: state.simbaReducer.liveSession
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);



