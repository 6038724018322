import React, {useState,useEffect} from 'react'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../firebase';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { Browser, isBrowser } from '../../HOC/BrowserChecker/browserChecker';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import {signInUser, mapUserDetails, signUpNewUser}  from '../../store/actions/actionCreaters/userActions';


import MindMirrorLive from '../../pages/MindMirrorLive';

import Auth from '../Auth/AuthChecker';
import Dashbaord from '../../pages/Dashboard';
import SignUp from '../../pages/SignUp';
import Error from '../../components/error/error';
import { detect } from 'detect-browser';
import Settings from '../../pages/Settings';
import LoginPage from '../../pages/Login';
import MindMirrorSetup from '../../pages/SetupMeditation'
import ConnectVilistus from '../../pages/ConnectVilistus';
import BlsLive from '../../pages/BlsLive';


const browser = detect();

const Divider = styled('div')(({ theme }) => ({
    width: '100%',
    height: 50
}));


type Props = {
    authentication: boolean,
    errorMessages: string[]
    signInUser: Function
    mapUserDetails: Function
    signUpNewUser: Function
    liveSession: boolean
}

export const VilistusCloudController = ({
    authentication,
    mapUserDetails,
    errorMessages,
    signInUser,
    signUpNewUser,
    liveSession
}: Props) => {

    return (
        <>
           <Routes> 
                <Route path={'/'} element={<ConnectVilistus/>} /> 
                <Route path={'/bls_live'} element={<BlsLive/>} />
           </Routes>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    authentication: state.userReducer.authenticated,
    errorMessages: state.errorReducer.errorMessages,
    liveSession: state.simbaReducer.liveSession
})

const mapDispatchToProps = {
    signInUser,
    mapUserDetails,
    signUpNewUser
}

export default connect(mapStateToProps, mapDispatchToProps)(VilistusCloudController)

