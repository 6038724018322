import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
  return `${value}`;
}

export default function DiscreteSlider(props: any) {
  return (
    <Box sx={{ width: `100%` }}>
      <Slider 
        defaultValue={props.default} 
        step={props.step} 
        marks={props.marks}
        min={props.min} 
        max={props.max} 
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        onChangeCommitted={props.changed}
      />
    </Box>
  );
}