import axios from 'axios'
import { from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import * as actionType from '../actions/actionTypes/activeTherapy';
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionCreater from '../actions/actionCreaters/activeTherapy';
import * as errorCreater from '../actions/actionCreaters/errorCreater';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const isTherapyActive = (action$: any) => action$.pipe(
    ofType(actionType.IS_THERAPY_ACTIVE),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/is_therapy_active/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return actionCreater.mapTherapyActivity(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const getActiveTherapyData = (action$: any) => action$.pipe(
    ofType(actionType.GET_ACTIVE_THERAPY_DATA),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/get_active_therapy_data/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return actionCreater.mapActiveTherapyData(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const deleteActiveTherapy = (action$: any) => action$.pipe(
    ofType(actionType.DELETE_ACTIVE_THERAPY),
    switchMap((action: any ) =>
      from(
        axios({
          method: "delete",
          url: `${baseUrl}/delete_active_therapy/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const deleteBeforeTherapyReport = (action$: any) => action$.pipe(
    ofType(actionType.DELETE_BEFORE_THERAPY_REPORT),
    switchMap((action: any ) =>
      from(
        axios({
          method: "delete",
          url: `${baseUrl}/delete_before_thrapy_report/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const deleteAfterTherapyReport = (action$: any) => action$.pipe(
    ofType(actionType.DELETE_AFTER_THERAPY_REPORT),
    switchMap((action: any ) =>
      from(
        axios({
          method: "delete",
          url: `${baseUrl}/delete_after_thrapy_report/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const createBeforeActiveTherapy = (action$: any) => action$.pipe(
    ofType(actionType.CREATE_BEFORE_ACTIVE_THERAPY),
    switchMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/create_before_therapy`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            sessionType: action.payload.sessionType,
            uid: action.payload.uid,
            mean: action.payload.mean
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return actionCreater.mapBeforeActiveTherapyData(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const createAfterActiveTherapy = (action$: any) => action$.pipe(
    ofType(actionType.CREATE_AFTER_ACTIVE_THERAPY),
    switchMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/create_after_therapy`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            sessionType: action.payload.sessionType,
            uid: action.payload.uid,
            mean: action.payload.mean
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return actionCreater.mapAfterActiveTherapyData(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

 
