import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router} from 'react-router-dom'
import Theme from './Theme'
import Layout from './HOC/Layout/Layout';
import VilistusCloudController from './containers/MindMirrorController/MindMirrorController';

const baseUrl = process.env.REACT_APP_BASE_URL;

const App: React.FC = () => {

  console.log('----------- BASE URL ---------------')
  console.log(baseUrl);
  console.log('----------- BASE URL ---------------')
  return (
    <ThemeProvider theme={Theme}>
        <Router>
      <Layout>
          <VilistusCloudController />
      </Layout>
        </Router>
    </ThemeProvider>
  )
}
 
export default App

// REACT_APP_BASE_URL = http://192.168.0.18:4025