import React, {Fragment} from 'react'
import { detect } from 'detect-browser';

const browser = detect();

let webview = false;
if(browser !== null) {
    browser.name.split('-').forEach(browser => {
        if(browser === 'webview') {
            webview = true;
        }
    })
}

type CheckerProps = {
    children: React.ReactNode
}

export function Browser(props:CheckerProps) {
    return (
        <Fragment>
           {!webview ? props.children : null}
        </Fragment>
    )
}
export function Webview(props:CheckerProps) {
    return (
        <Fragment>
           {webview ? props.children : null}
        </Fragment>
    )
}
export const isBrowser = webview === false;
// export const isWebview = webview === true;