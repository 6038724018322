import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as errorCreater from '../actions/actionCreaters/errorCreater';
import * as simbaReporting from '../actions/actionTypes/simbaReporting';

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


export const createSimbaReport = (action$: any) => action$.pipe(
    ofType(simbaReporting.CREATE_NEW_SIMBA_REPORT),
    switchMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/create_new_simba_report`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            uid: action.payload.uid,
            levels: action.payload.levels
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );