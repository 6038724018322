import * as actionType from '../actionTypes/calibrateBaseline';

export const openCalibrateBaselineWebsocket = (payload: any) => {
    return {
        type: actionType.OPEN_CALIBRATE_BASELINE_WEBSOCKET,
        payload
    }
}

export const mapBaselineCalibrationData = (payload: any) => {
    return {
        type: actionType.MAP_BASELINE_CALIBRATION_DATA,
        payload
    }
}

export const resetCalibrationBaseline = () => {
    return {
        type: actionType.RESET_CALIBRATION_BASELINE
    }
}