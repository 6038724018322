
// import { Observable, from, of } from "rxjs";
// import { switchMap, map, catchError } from "rxjs/operators";
// import { ofType } from "redux-observable";
// import axios from "axios";
// import mqtt from "mqtt";
// import * as errorType from '../actions/actionTypes/errorTypes';
// import * as actionType from "../actions/actionTypes/liveTherapyBaseline";
// import * as actionCreater from "../actions/actionCreaters/liveTherapyBaseline";
// import * as actionCalibration from "../actions/actionCreaters/calibrateBaseline";
// import * as actionSimba from '../actions/actionCreaters/simba'
// import * as signalCreater from "../actions/actionCreaters/signalCheck";

// const baseUrl = process.env.REACT_APP_BASE_URL;
// const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


// export const therapyBaselineWebsocket = (action$: any) => action$.pipe(
//     ofType(actionType.OPEN_BASELINE_WEBSOCKET),
//     switchMap((action: any) => {
//       return Observable.create((obs: any) => {
//         var client = mqtt.connect(mqttWsUrl, { rejectUnauthorized: false });
//         client.on("connect", function () {
//           client.subscribe(`/mindstreams/M1-${action.payload.uid}/data`,
//             function (err) {
//               if(!err) {
//                 console.log(`/mindstreams/M1-${action.payload.uid}/data`);
//                 console.log('Baseline mqtt connected')
//               }
//             }
//           );
//           client.subscribe(
//             `/mindstreams/M1-${action.payload.uid}/signal-quality`,
//             function (err) {
//               console.log(err);
//               if(!err) {
//                 console.log('Signal check mqtt connected')
//               }
//             }
//           );
//         });

//         // client.on("connect", function () {
          
//         // });

//         client.on("message", function (topic, message: any) {
//           if (message) {
//             const data = JSON.parse(message);
//             if(topic === `/mindstreams/M1-${action.payload.uid}/data`) {
//               if(data.Level && data.LHS && data.RHS) {
//                 obs.next({
//                   context: 'simba-live',
//                   level: data.Level,
//                   lhs: data.LHS,
//                   rhs: data.RHS,
//                   feedback: data.Feedback
//                 })
//               }
//             } else if(topic === `/mindstreams/M1-${action.payload.uid}/signal-quality`) {
//               // console.log('THIS IS THE SIGNAL')
//               // console.log(data);
//               if(data.signalCheck.length > 0) {
//                 obs.next({
//                   context: 'signal',
//                   data
//                 });
//               }
//             }
//           } 
//         });
//       }).pipe(
//         map((payload: any) => {
//           if(payload.context === 'signal') {
//             return signalCreater.mapSignalCheckData(payload)
//           } else if(payload.context === 'simba-live') {
//             return actionSimba.updateLiveSimbaValues(payload)
//           }
//         })
//       );
//     })
//   );




import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionType from "../actions/actionTypes/liveTherapyBaseline";
import * as actionCreater from "../actions/actionCreaters/liveTherapyBaseline";
import * as actionCalibration from "../actions/actionCreaters/calibrateBaseline";
import * as actionSimba from '../actions/actionCreaters/simba'

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


export const therapyBaselineWebsocket = (action$: any) => action$.pipe(
    ofType(actionType.OPEN_BASELINE_WEBSOCKET),
    switchMap((action: any) => {
      return Observable.create((obs: any) => {
        // var client = mqtt.connect(mqttWsUrl, { rejectUnauthorized: false });
        // client.on("connect", function () {
        //   client.subscribe(`/mindstreams/M1-${action.payload.uid}/data`,
        //     function (err) {
        //       if(!err) {
        //         console.log(`/mindstreams/M1-${action.payload.uid}/data`);
        //         console.log('Baseline mqtt connected')
        //       }
        //     }
        //   );
        // });

        // client.on("connect", function () {
        //   client.subscribe(`/mindstreams/M1-${action.payload.uid}/calibration`,
        //     function (err) {
        //       console.log(err);
        //       if(!err) {
        //         console.log('Calibration Websocket is open')
        //       }
        //     }
        //   );
        // });

        // client.on("message", function (topic, message: any) {
        //   if (message) {
        //     const data = JSON.parse(message);
        //     if(topic === `/mindstreams/M1-${action.payload.uid}/data`) {
        //       if(data.Level && data.LHS && data.RHS) {
        //         obs.next({
        //           context: 'simba-live',
        //           level: data.Level,
        //           lhs: data.LHS,
        //           rhs: data.RHS,
        //           feedback: data.Feedback
        //         })
        //       }
        //     }
        //   } 
        // });
      }).pipe(
        map((payload: any) => {
          if(payload.context === 'compare') {
            // return actionCalibration.mapBaselineCalibrationData(payload)
          } else if(payload.context === 'simba-live') {
            return actionSimba.updateLiveSimbaValues(payload)
          }
        })
      );
    })
  );