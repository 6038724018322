import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/liveTherapyBaseline';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface LiveTherapyBaselineState {
   sequenceNumber: number
}

let intialState: LiveTherapyBaselineState = {
  sequenceNumber: 0
}

const liveTherapyBaseline = (
    state:  LiveTherapyBaselineState = intialState, 
    action: AnyAction
): LiveTherapyBaselineState => {
    switch(action.type) {
       case actionType.MAP_BASELINE_DATA : return mapBaselineData(state, action.payload);
       case actionType.RESET_BASELINE_SEQUENCE_NUMBER : return updateObj(state, {sequenceNumber: 0})
        default:
            return state;
    }
}

const mapBaselineData = (state: LiveTherapyBaselineState, payload: any) => {
    // console.log(payload);
    return updateObj(state, {sequenceNumber: payload.sequenceNumber});
}

export default liveTherapyBaseline;