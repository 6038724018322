import * as actionType from '../actionTypes/errorTypes';

export const addErrorMessage = (payload: string) => {
    return {
        type: actionType.ADD_ERROR_MESSAGE,
        payload
    }
}

export const consoleLogHttp = (payload: string) => {
    return {
        type: actionType.CONSOLE_LOG_REQUESTS,
        payload
    }
}