import * as actionTypes from '../actionTypes/reporting';

export const createNewTherapyReport = (payload: any) => {
    return {
        type: actionTypes.CREATE_NEW_THERAPY_REPORT,
        payload
    }
}

export const mapTherapyReport = (payload: any) => {
    return {
        type: actionTypes.MAP_PAST_THERAPY_REPORT,
        payload
    }
}

export const getAllPastTherapyReports = (payload: any) => {
    return {
        type: actionTypes.GET_ALL_PAST_THERAPY_REPORTS,
        payload
    }
}

export const mapAllPastTherapyReports = (payload: any) => {
    return {
        type: actionTypes.MAP_ALL_PAST_THERAPY_REPORTS,
        payload
    }
}

export const getPastTherapyReport = (payload: any) => {
    return {
        type: actionTypes.GET_PAST_THERAPY_REPORT,
        payload
    }
}