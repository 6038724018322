import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/simbaReporting';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface SimbaReportingState {
    individualSimbaReport: any
    averageLevel: number
    levels: number[]
    start: number[]
    end: number[]
    allSimbaReports: any
}

let intialState:  SimbaReportingState = {
    individualSimbaReport: null,
    averageLevel: 19,
    levels: [],
    start: [],
    end: [],
    allSimbaReports: []
}

const simbaIndividualReport = (
    state:  SimbaReportingState = intialState, 
    action: AnyAction
): SimbaReportingState => {

    switch(action.type) {
        case actionType.SET_AVERAGE_LEVEL : return updateObj(state, { averageLevel: action.payload });
        case actionType.MAP_SIMBA_REPORT : return mapIndividualReport(state, action.payload);
        case actionType.MAP_ALL_SIMBA_REPORTS : return updateObj(state, {allSimbaReports: action.payload});

        default:
            return state;
    }
}


const mapIndividualReport  = (state: SimbaReportingState, payload: any) => {
    return updateObj(state, {
        individualSimbaReport: payload,
        averageLevel: payload.averageLevel,
        levels: payload.levels,
        start: payload.start,
        end: payload.end
    })
}


export default simbaIndividualReport;