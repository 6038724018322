import React from 'react'
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';


const Container = styled(Paper)(() => ({
    padding: 5,
    marginBottom: 5,
    borderRadius: 10
}));

export default function CardContainer(props: any) {

  return (
    <Container>{props.children}</Container>
  )
}