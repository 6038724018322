import { createTheme } from '@mui/material/styles';


declare module '@mui/material/styles/createPalette' {
    interface CommonColors {
        blue: string
        orange: string
        salmon: string
        text: string
    }
}

export const primaryColor = (opacity: number) => `rgba(242,70,6,${opacity})`
export const secondaryColor = (opacity: number) => `rgba(242, 189, 158,${opacity})`

const secondary = '#2B2A4C';
const primary = '#EA906C';
const error = "#fa8072";
const text = "#adb5bd"

export default createTheme({
    palette: {
        common: {
            blue: primary,
            orange: secondary,
            salmon: error
        },
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary
        },
        error: {
            main: error
        },
    },
});