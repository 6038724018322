import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/sessionType';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface SessionTypeState {
    sessionType: string
}

let intialState: SessionTypeState = {
    sessionType: 'SIMBA'
}

const sessionType = (
    state:  SessionTypeState = intialState, 
    action: AnyAction
): SessionTypeState => {
    switch(action.type) {
        case actionType.SET_SESSION_TYPE: return updateObj(state, {sessionType: action.payload});
        default:
            return state;
    }
}



export default sessionType;