export const IS_THERAPY_ACTIVE = 'IS_THERAPY_ACTIVE';
export const MAP_THERAPY_ACTIVITY = ' MAP_THERAPY_ACTIVITY';
export const MAP_BEFORE_ACTIVE_THERAPY_DATA = 'MAP_BEFORE_ACTIVE_THERAPY_DATA';
export const MAP_AFTER_ACTIVE_THERAPY_DATA = 'MAP_AFTER_ACTIVE_THERAPY_DATA';
export const GET_ACTIVE_THERAPY_DATA = 'GET_ACTIVE_THERAPY_DATA';
export const MAP_ACTIVE_THERAPY_DATA = 'MAP_ACTIVE_THERAPY_DATA';
export const CREATE_BEFORE_ACTIVE_THERAPY = 'CREATE_BEFORE_ACTIVE_THERAPY';
export const CREATE_AFTER_ACTIVE_THERAPY = 'CREATE_AFTER_ACTIVE_THERAPY';
export const RESET_ACTIVE_THERAPY = 'RESET_ACTIVE_THERAPY';
export const DELETE_ACTIVE_THERAPY = 'DELETE_ACTIVE_THERAPY';
export const SET_TYPE_OF_ACTIVE_THERAPY = 'SET_TYPE_OF_ACTIVE_THERAPY';

export const DELETE_BEFORE_THERAPY_REPORT = 'DELETE_BEFORE_THERAPY_REPORT';
export const DELETE_AFTER_THERAPY_REPORT = 'DELETE_AFTER_THERAPY_REPORT';