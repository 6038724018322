import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionType from "../actions/actionTypes/calibrateBaseline";
import * as actionCreater from "../actions/actionCreaters/calibrateBaseline";
import { SlowBuffer } from "buffer";

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;

export const calibrateBaselineWebsocket = (action$: any) =>
  action$.pipe(
    ofType(actionType.OPEN_CALIBRATE_BASELINE_WEBSOCKET),
    switchMap((action: any) => {
      return Observable.create((obs: any) => {
        // console.log('connection calibration websocket');
        // console.log(action.payload.uid);
        // var client = mqtt.connect(mqttWsUrl, { rejectUnauthorized: false });
        // client.on("connect", function () {
        //   client.subscribe(`/mindstreams/C1-${action.payload.uid}/calibration`,
        //     function (err) {
        //       console.log(err);
        //       if(!err) {
        //         console.log('Calibration Websocket is open')
        //       }
        //     }
        //   );
        // });

        // client.on("message", function (topic, message: any) {
        //   console.log(topic);

     
        //   const data = JSON.parse(message);
        //   if (data) {

        //     console.log('-------------- CALIBRATION DATA -----------------')
        //     console.log(data);
        //     console.log('-------------- CALIBRATION DATA -----------------')
        //     if(data.EOF === "YES") {
        //       // client.end();
        //     } else {
        //       obs.next(data);
        //     }
          // }
        // }); 
      }).pipe(map((payload) => actionCreater.mapBaselineCalibrationData(payload)));
    })
  );