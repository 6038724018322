import * as actionTypes from '../actionTypes/activeTherapy';

export const isTherapyActive = (payload: any) => {
    return {
        type: actionTypes.IS_THERAPY_ACTIVE,
        payload
    }
}
export const mapTherapyActivity = (payload: any) => {
    return {
        type: actionTypes.MAP_THERAPY_ACTIVITY,
        payload
    }
}

export const getActiveTherapyData = (payload: any) => {
    return {
        type: actionTypes.GET_ACTIVE_THERAPY_DATA,
        payload,
    }
}

export const mapActiveTherapyData = (payload: any) => {
    return {
        type: actionTypes.MAP_ACTIVE_THERAPY_DATA,
        payload
    }
} 
export const mapBeforeActiveTherapyData = (payload: any) => {
    return {
        type: actionTypes.MAP_BEFORE_ACTIVE_THERAPY_DATA,
        payload
    }
}
export const mapAfterActiveTherapyData = (payload: any) => {
    return {
        type: actionTypes.MAP_AFTER_ACTIVE_THERAPY_DATA,
        payload
    }
}

export const createBeforeActiveTherapy = (payload: any) => {
    return {
        type: actionTypes.CREATE_BEFORE_ACTIVE_THERAPY,
        payload
    }
}
export const createAferActiveTherapy = (payload: any) => {
    return {
        type: actionTypes.CREATE_AFTER_ACTIVE_THERAPY,
        payload
    }
}

export const resetActiveTherapy = (payload: any) => {
    return {
        type: actionTypes.RESET_ACTIVE_THERAPY,
        payload
    }
}
export const deleteActiveTherapy = (payload: any) => {
    return {
        type: actionTypes.DELETE_ACTIVE_THERAPY,
        payload
    }
}
export const setTypeOfActiveTherapy = (payload: string) => {
    return {
        type: actionTypes.SET_TYPE_OF_ACTIVE_THERAPY,
        payload
    }
}

export const deleteBeforeTherapy = (payload: any) => {
    return {
        type: actionTypes.DELETE_BEFORE_THERAPY_REPORT,
        payload
    }
}
export const deleteAfterTherapy = (payload: any) => {
    console.log('hello');
    return {
        type: actionTypes.DELETE_AFTER_THERAPY_REPORT,
        payload
    }
}