import React, {useState} from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../store/store'
import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';
import { isBrowser } from '../../HOC/BrowserChecker/browserChecker';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { sendMindMirrorStreamRequest } from '../../store/actions/actionCreaters/backendCommands';
import { UserForm } from '../../interfaces/user';
import { setReaderPort, setSerialPort, setWriterPort, startMindMirrorStream } from '../../store/actions/actionCreaters/mindMirror';
import { startCommand, stopCommand } from '../../assets/blsCommands';
import { black, lightGreenColor } from '../../assets/colors';

const Container = styled(Paper)(({ theme }: any) => ({
    ...theme.typography.body2,
    elevation: 3,
   padding: 20,
   margin: 10
}));

declare global {
    interface Navigator {
      serial: any;
    }
  }


interface MindMirrorSetupProps {
    userProfile: UserForm
    sendMindMirrorStreamRequest: Function
    serialPort: any
    writerPort: any
    readerPort: any
    setSerialPort:Function
    setWriterPort: Function
    setReaderPort: Function
    startMindMirrorStream: Function
}


const MindMirrorSetup = ({
    userProfile,
    sendMindMirrorStreamRequest,
    startMindMirrorStream,
    serialPort,
    writerPort,
    readerPort,
    setSerialPort,
    setReaderPort,
    setWriterPort
}: MindMirrorSetupProps) => {

    const navigate = useNavigate();
    async function connectDeviceFromSerialPort() {
        try {
                // setConnectionStatus('Connecting...')
                let port = await navigator.serial.requestPort();
                setSerialPort(port)
                // setSerialPort(port);
                await port.open({baudRate: 115200});
                let writer = await port.writable.getWriter();
                setWriterPort(writer)
                let reader = await port.readable.getReader();
                setReaderPort(reader)
                // setConnectionStatus('testing connection...')
        } catch(error) {
            console.log(error);
        }       
    }

    // async function testConnection() {
    //     try {
            
    //         const start = startCommand()
    //         await writerPort.write(start);
    //         let data = 0
    //         while(data < 50) {
    //             const { value, done } = await readerPort.read();
    //             console.log(value)
    //             data = data + 1
    //             // sendVilistusData(value, userProfile.uid)
    //         } 
    //         const stop = stopCommand();
    //         await writerPort.write(stop);
    //         // setConnectionStatus('Connected')
    //         console.log('Vilistus Serial Port Connected');
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    let connectionStatus = serialPort && readerPort && writerPort
    // if(connectionStatus) {
    //     testConnection()
    // }

    let connected = (
        <>
            <div style={{
                padding: 5,
                background: lightGreenColor(.5),
                border: `2px solid ${lightGreenColor(1)}`,
                borderRadius: 3,
                color: black(.5),
                fontWeight: 700,
                textAlign: 'center',
                marginBottom: 5

            }}>BLS Connected</div>
            <PrimaryButton clicked={() => navigate('/bls_live')}>Continue</PrimaryButton>
        </>
    )

  return (
    <>
        {!connectionStatus ? <PrimaryButton clicked={() => connectDeviceFromSerialPort()}>REQUEST CONNECTION</PrimaryButton> : connected}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
    userProfile: state.userReducer.userProfile,
    serialPort: state.mindMirror.serialPort,
    writerPort: state.mindMirror.writerPort,
    readerPort: state.mindMirror.readerPort,
})

const mapDispatchToProps = {
    sendMindMirrorStreamRequest,
    setSerialPort,
    setWriterPort,
    setReaderPort,
    startMindMirrorStream
}

export default connect(mapStateToProps, mapDispatchToProps)(MindMirrorSetup)