import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { isBrowser } from '../../HOC/BrowserChecker/browserChecker';
import { useNavigate } from 'react-router-dom';


import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { primaryColor, secondaryColor } from '../../assets/colors';


// const navContainer = {
    
// }



const NavBottomContainer = styled(Paper)(({ theme }) => ({
    height: 55,
    zIndex: 100000, 
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
    
}));

const NavTopContainer = styled(Paper)(({ theme }) => ({
    height: 50,
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15
}));

const ButtonTopNavigtionContainer = styled(BottomNavigation)(({ theme }) => ({
    // background: 'linear-gradient(0deg, rgba(34,195,173,1) 0%, rgba(45,166,253,1) 100%)',
    background: `linear-gradient(95deg, ${primaryColor(1)} 0%, ${secondaryColor(1)} 100%)`,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15
}));
const ButtonBottomNavigtionContainer = styled(BottomNavigation)(({ theme }) => ({
    background: 'linear-gradient(0deg, rgba(34,195,173,1) 0%, rgba(45,166,253,1) 100%)',
    borderTopLeftRadius: 15,
    borderTopRightRadius:15
}));

const NavigationButtonActions = styled(BottomNavigationAction)(({ theme }) => ({
    color: "#ffffff",
}));

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();


    let navigationContent = (
        <ButtonBottomNavigtionContainer
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
        <NavigationButtonActions onClick={() => navigate('/')} icon={<HomeIcon />} />
        <NavigationButtonActions onClick={() => navigate('/session_type')} icon={<SelfImprovementIcon />} />
        {/* <NavigationButtonActions onClick={() => navigate('/')} icon={<EqualizerIcon />} /> */}
        <NavigationButtonActions onClick={() => navigate('/settings')} icon={<SettingsIcon />} />
    </ButtonBottomNavigtionContainer>
    );

            console.log(isBrowser);

    if(isBrowser) {
        navigationContent = (<ButtonTopNavigtionContainer
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
        {/* <NavigationButtonActions onClick={() => navigate('/')} icon={<HomeIcon />} /> */}
        <NavigationButtonActions onClick={() => navigate('/')} icon={<SelfImprovementIcon />} />
        {/* <NavigationButtonActions onClick={() => navigate('/')} icon={<EqualizerIcon />} /> */}
        <NavigationButtonActions onClick={() => navigate('/settings')} icon={<SettingsIcon />} />
    </ButtonTopNavigtionContainer>)
    }


    let display = (
        <NavBottomContainer
            elevation={3}
        >
            {navigationContent}
        </NavBottomContainer>
    )

    if(isBrowser) {
        display = (<NavTopContainer
            elevation={3}
        >
            {navigationContent}
        </NavTopContainer>)
    } 






  return (
    <>
        {display}
    </>
  );
}