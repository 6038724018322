import * as actionType from '../actionTypes/signalCheck';

export const resetSignalCheck = () => {
    return {
        type: actionType.RESET_SIGNAL_CHECK,
    }
}

export const openSignalCheckWebsocket = (payload: any) => {
    console.log('OPEN SIGNAL WEBSOCKET REQUEST')
    return {
        type: actionType.OPEN_SIGNAL_CHECK_WEBSOCKET,
        payload
    }

}

export const mapSignalCheckData = (payload: any) => {
    return {
        type: actionType.MAP_SIGNAL_CHECK_DATA,
        payload
    }
}
