import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/binoralEEG';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface BinoralEEGState {
  frequency: number
}

let intialState: BinoralEEGState = {
  frequency: 200
}

const binoralEEG = (
    state:  BinoralEEGState = intialState, 
    action: AnyAction
): BinoralEEGState => {
    switch(action.type) {
      case actionType.MAP_BINORAL_EEG_DATA : return mapBinoralEEGData(state, action.payload);
     
      default:
            return state;
    }
}

const mapBinoralEEGData = (state: BinoralEEGState, payload: any) => {
    
    console.log(payload.values[0]);

    // console.log(state.frequency);
    return updateObj(state, {frequency: payload.values[0]});
}

export default binoralEEG;