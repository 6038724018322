import axios from 'axios'
import { from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import * as actionType from '../actions/actionTypes/userTypes';
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionCreater from '../actions/actionCreaters/userActions';
import { UserForm } from '../../interfaces/user';


const baseUrl = process.env.REACT_APP_BASE_URL;
console.log(baseUrl)

export const setupNewUser = (action$: any) => action$.pipe(
    ofType(actionType.SIGN_UP_NEW_USER),
    switchMap((action: any ) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/setup_new_user`,
          // url: `http://localhost:4025/setup_new_user`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            name: action.payload.name,
            uid: action.payload.uid,
            email: action.payload.email,
            app: action.payload.app
          },
        })
          .then((result) => result.data)
      )
    ),
    map((payload: UserForm ) => {
      return actionCreater.mapUserDetails(payload)
  }),
  catchError((error) => {
    return of({
    type: errorType.ADD_ERROR_MESSAGE,
    payload: error.response.data,
    error: true
  })})
  );


export const getUserDetails = (action$: any) => action$.pipe(
    ofType(actionType.SIGN_IN_USER),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/get_user_details/${action.payload.uid}`,
          // url: `${baseUrl}/get_coach_details/${4}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: UserForm) => {
        return actionCreater.mapUserDetails(payload)
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );




