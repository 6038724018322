import React from "react";
import { AnyAction } from "redux";
import * as actionType from '../../actions/actionTypes/userTypes';
import { updateObj } from '../../utilities';

import { UserForm } from "../../../interfaces/user";

export interface UserReducerState {
    authenticated: boolean,
    userProfile: UserForm,
    sessionContext: string
}

let intialState: UserReducerState = {
    authenticated: false,
    userProfile: {
        name: '',
        uid: '',
        email: '',
        app: ''
    },
    sessionContext: '/live_therapy_baseline'
}

const userReducer = (
    state: UserReducerState = intialState, 
    action: AnyAction
): UserReducerState => {
    switch(action.type) {
        case actionType.SIGN_UP_NEW_USER : return signUpNewUser(state);
        case actionType.MAP_USER_DETAILS : return mapUserDetails(state, action.payload)
        case actionType.SIGN_IN_USER : return signInUser(state)
        case actionType.SIGN_OUT_USER : return signOutUser(state);
        case actionType.SET_SESSION_CONTEXT : return setSessionContext(state, action.payload)
        
        default:
            return state;
    }
}

const setSessionContext = (state: UserReducerState, payload: any) => {
    console.log('---- SESSION CONTEXT -----------------------')
    console.log(payload)
    console.log('---------------------------')
    return updateObj(state, {sessionContext: payload})
}

const signUpNewUser = (state: UserReducerState) => {
    console.log('signing up new user...');
    return state
}

const mapUserDetails = (state: UserReducerState, payload: any) => {
    console.log('THIS IS HAPPENING');
    console.log(payload);
    return updateObj(state, {
        authenticated: true,
        userProfile: payload
    })
}

const signInUser = (state: UserReducerState) => {
    console.log('Signing in the user...');
    return state
}

const signOutUser = (state: UserReducerState) => {
    console.log('is this being reached');
    return updateObj(state, {
        authenticated: false,
        userProfile: null
    })
}



export default userReducer;