import { Observable, from } from "rxjs";
import { switchMap, map, delay, mapTo } from "rxjs/operators";
import { ofType } from "redux-observable";
import mqtt from "mqtt";


import * as actionType from '../actions/actionTypes/mindMirror';
import * as actionCreater from '../actions/actionCreaters/mindMirror';


let webSocketUrl = process.env.REACT_APP_MQTT_WS


// let client = mqtt.connect(webSocketUrl, { 
//     // let client = mqtt.connect('wss://vilistus.cloud:8083', { 
//       rejectUnauthorized: false
// });

export const mindMirrorStream = (action$: any) =>
  action$.pipe(
    ofType(actionType.START_MIND_MIRROR_STREAM),
    switchMap((action: any) => {
      return Observable.create((obs:any) => {
        
        // let clientobv = mqtt.connect(webSocketUrl, { 
        // // let client = mqtt.connect('wss://vilistus.cloud:8083', { 
        //   rejectUnauthorized: false
        // });

        // clientobv.on("connect", function (error: any) {
        //   // console.log(error);
        //   // console.log('trying to connect...')
        //   clientobv.subscribe(`/mindstreams/M1-${action.payload.uid}/data`,
        //     function (err) {
        //       console.log('MQTT CONNECTED');
        //     }
        //   );
        // });

        // clientobv.on("message", function (topic: string, message: any) {
        //   if (message) {
        //     const data = JSON.parse(message);
        //     if(data.values && data.signalCheck) {
        //       // console.log(data);
        //       obs.next({
        //         values: data.values,
        //         signalCheck: data.signalCheck
        //       });
        //     }
        //   }
        // });
      }).pipe(map(mindMirrorPacket => actionCreater.mapMindMirrorValues(mindMirrorPacket)));
    })
  );  


export function sendVilistusData(dataPacket: any, topic: any) {
//   if(client) {
//     client.publish(`/mindstreams/android/${topic}/data`, JSON.stringify(`${dataPacket}`));
//   }
}

