// import { SensorsOffSharp } from "@mui/icons-material";
// import React from "react";
// import { AnyAction } from "redux";
// // import { ConnectableObservable } from "rxjs";
// import * as actionType from '../../actions/actionTypes/signalCheck';
// import { RootState } from "../../store";
// import { updateObj } from '../../utilities';



// export interface SignalCheckProps {
//     signalLengthNeeded: number,
//     connect: string[],
//     signalCheck: number,
//     signalCheckLength: number,
//     overFiftyPercent: number,
//     signalCheckComplete: boolean,
//     signalRecord: any
// }

// let intialState:  SignalCheckProps = {
//     signalLengthNeeded: 10,
//     connect: ['red','red','red','red'],
//     signalCheck: 0,
//     signalCheckLength: 0,
//     overFiftyPercent: 0,
//     signalCheckComplete: false,
//     signalRecord: null
// }

// const signalCheckReducer = (
//     state:  SignalCheckProps = intialState, 
//     action: AnyAction
// ):  SignalCheckProps => {
//     switch(action.type) {
//         case actionType.MAP_SIGNAL_CHECK_DATA : return mapSignalCheck(state,action.payload)
//         case actionType.RESET_SIGNAL_CHECK : return resetSignalCheck(state, action.payload.signalCheck);
//         default:
//             return state;
//     }
// }


// const mapSignalCheck = (state: SignalCheckProps, payload: any) => {
// // console.log(payload);
//     return updateObj(state, {
//          signalRecord: payload
//     });
      
// }

// const resetSignalCheck = (state: SignalCheckProps, action: any) => {
//     return updateObj(state, {
//         signalLengthNeeded: 10,
//         connect: ['red','red','red','red'],
//         signalCheck: 0,
//         signalCheckLength: 0,
//         overFiftyPercent: 0,
//         signalCheckComplete: false
//     });
// }
// export default signalCheckReducer;




import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/signalCheck';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface SignalCheckProps {
    signalLengthNeeded: number,
    connect: string[],
    signalCheck: number,
    signalCheckLength: number,
    overFiftyPercent: number,
    signalCheckComplete: boolean
}

let intialState:  SignalCheckProps = {
    signalLengthNeeded: 10,
    connect: ['red','red','red','red'],
    signalCheck: 0,
    signalCheckLength: 0,
    overFiftyPercent: 0,
    signalCheckComplete: false
}

const signalCheckReducer = (
    state:  SignalCheckProps = intialState, 
    action: AnyAction
):  SignalCheckProps => {
    switch(action.type) {
        case actionType.MAP_SIGNAL_CHECK_DATA : return mapSignalCheck(state, action.payload);
        case actionType.RESET_SIGNAL_CHECK : return resetSignalCheck(state, action.payload);
        default:
            return state;
    }
}


const mapSignalCheck = (state: SignalCheckProps, payload: any) => {
    if(payload.signalCheck !== undefined) {
            let signalLength = state.signalCheckLength + 1;
            let sensors = payload.signalCheck;
            // console.log(sensors);
            let sensorColors = sensors.map((el: any) => {
            if(el === 0) {
                return 'red';
            } else if(el === 1) {
                return 'orange';
            } else if(el === 2) {
                return 'yellow';
            } else if(el === 3) {
                return '#BAFFB4'
            }
            });
        
    
            let status = true;
        
            sensors.forEach((sensor: any)=> {
            // if(sensor < 3) {
            //     status = false
            // }
            })
        
            let signalCount = state.signalCheck;
        
            if (status) {
            signalCount++; 
            } else {
            signalCount = 0;
            }
        
            let percentage = Math.round((signalCount / state.signalLengthNeeded) * 100);
            let overFifty = state.overFiftyPercent;
            if(percentage > 50) {
            overFifty = overFifty + 1;
            }
        
        
            return updateObj(state, {
            connect: sensorColors,
            signalCheck: signalCount,
            signalCheckLength: signalLength,
            overFiftyPercent: 0
            });
        } else {
            return state;
        }   
}

const resetSignalCheck = (state: SignalCheckProps, action: any) => {
    return updateObj(state, {
        signalLengthNeeded: 10,
        connect: ['red','red','red','red'],
        signalCheck: 0,
        signalCheckLength: 0,
        overFiftyPercent: 0,
        signalCheckComplete: false
    });
}
export default signalCheckReducer;