export const textColor = '#6B6B6B'
export const subTitleColor = '#ccc'
export const errorRed = '#FF9494';
export const navyBlue = '#05445E'

export const white = (opacity: number) => `rgba(250,250,250, ${opacity})`
export const black = (opacity: number) => `rgba(0,0,0,${opacity})`

export const primaryColor = (opacity: number) => `rgba(43, 42, 76,${opacity})`

// export const primaryColor = (opacity: number) => `rgba(43, 42, 76, ${opacity})`
export const secondaryColor = (opacity: number) => `rgba(234, 144, 108,${opacity})`
// export const secondaryColor = (opacity) => `rgba(164,156,250,${opacity})`

export const lightGreenColor = (opacity: number) => `rgba(186,255,180, ${opacity})`
export const lightBlue = (opacity: number) => `rgba(114,180,212,${opacity})`