import React from 'react'
import Blslive from '../containers/BlsWeb/blslive'

export default function BlsLive() {
  return (
    <>
        <Blslive/>
    </>
  )
}
