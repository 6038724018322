import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/simba';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';

export interface SimbaLiveState {
    sessionContext: number,
    sessionLength: number,
    levels: number[],
    feedback: number,
    lhs: number[],
    rhs: number[],
    liveSession: boolean
}

let intialState:  SimbaLiveState = {
    sessionContext: 1,
    sessionLength: 300000,
    levels: [],
    feedback: 0,
    lhs: [],
    rhs: [],
    liveSession: false
}

const signalCheckReducer = (
    state:  SimbaLiveState = intialState, 
    action: AnyAction
): SimbaLiveState => {
    switch(action.type) {
        case actionType.SET_SIMBA_CONTEXT : return updateObj(state, {sessionContext: action.payload});
        case actionType.SET_SIMBA_LENGTH : return setLength(state, action.payload);
        case actionType.UPDATE_LIVE_SIMBA_VALUES : return updateLiveSimbaValues(state, action.payload);
        case actionType.LIVE_SESSION_STATUS : return updateObj(state, {liveSession: action.payload});
        
        default:
            return state;
    }
}


const setLength = (state: SimbaLiveState, payload: any) => {

    return updateObj(state, {sessionLength: payload})
}

const updateLiveSimbaValues = (state:SimbaLiveState, payload: any) => {
    console.log(payload)

    let levels = state.levels

    levels = levels.concat(payload.level);

    return updateObj(state, {
        levels,
        feedback: payload.feedback,
        lhs: payload.lhs,
        rhs: payload.rhs
    })
}



export default signalCheckReducer;