import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as actionType from "../actions/actionTypes/binoralEEG";
import * as actionCreater from "../actions/actionCreaters/binoralEEG";
import * as actionCalibration from "../actions/actionCreaters/calibrateBaseline";

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


export const binoralEEGWebsocket = (action$: any) => action$.pipe(
    ofType(actionType.OPEN_BINORAL_EEG_WEBSOCKET),
    switchMap((action: any) => {
      return Observable.create((obs: any) => {
        // var client = mqtt.connect(mqttWsUrl, { rejectUnauthorized: false });
        // client.on("connect", function () {
        //   client.subscribe(`/mindstreams/C1-${action.payload.uid}/data`,
        //     function (err) {
        //       if(!err) {
        //         console.log('Binoral mqtt connected')
        //       }
        //     }
        //   );
        // });

        // client.on("message", function (topic, message: any) {
        //   if (message) {
        //     const data = JSON.parse(message);
        //       if (data && data.values) {
        //         obs.next(data);
        //         } else if(data.EOF === 'YES') {
        //             client.end();
        //         }  
        //   } 
        // });
      }).pipe(
        map((payload: any) => {
            return actionCreater.mapBinoralEEGnData(payload)
        //   if(payload.context === 'live') {
        //     return actionCreater.mapBaselineData(payload)
        //   } else if(payload.context === 'baseline') {
        //     return actionCalibration.mapBaselineCalibrationData(payload.data)
        //   }
        })
      );
    })
  );