import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as errorCreater from '../actions/actionCreaters/errorCreater';
import * as actionType from "../actions/actionTypes/reporting";
import * as actionCreater from "../actions/actionCreaters/reporting";
import * as simbaCreater from '../actions/actionCreaters/simbaReporting';

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


export const createNewTherapyReport = (action$: any) => action$.pipe(
    ofType(actionType.CREATE_NEW_THERAPY_REPORT),
    switchMap((action: any) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/create_new_therapy_comparison`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            uid: action.payload.uid,
            before: action.payload.before,
            after: action.payload.after
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return actionCreater.mapTherapyReport(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


  export const getAllTherapyReports = (action$: any) => action$.pipe(
    ofType(actionType.GET_ALL_PAST_THERAPY_REPORTS),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/get_all_simba_reports/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return simbaCreater.mapAllSimbaReports(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  )


  export const getTherapyReport = (action$: any) => action$.pipe(
    ofType(actionType.GET_PAST_THERAPY_REPORT),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/get_individual_simba_report/${action.payload.reportId}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      ) 
    ),
    map((payload: any) => {
        return simbaCreater.mapSimbaReport(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  )
