import * as actionType from '../actionTypes/backendCommands';

export const signalCheckRequest = (payload: any) => {
    return {
        type: actionType.SEND_SIGNAL_CHECK_REQUEST,
        payload
    }
}

export const baslineStreamRequest = (payload: any) => {
    return {
        type: actionType.SEND_BASELINE_STREAM_REQUEST,
        payload
    }
}

export const stopStream = (payload: any) => {
    return {
        type: actionType.STOP_STREAM_REQUEST,
        payload
    }
}

export const sendCalibrateBaselineRequest = (payload: any) => {
    return {
        type: actionType.SEND_CALIBRATE_BASELINE_REQUEST,
        payload
    }
}

export const sendBinoralEEGStreamRequest = (payload: any) => {
    return {
        type: actionType.SEND_BINORAL_EEG_STREAM_REQUEST,
        payload
    }
}

export const sendCancelStreamRequest = (payload: any) => {
    return {
        type: actionType.SEND_CANCEL_STREAM_REQUEST,
        payload
    }
}

export const sendSimbaStreamRequest = (payload: any) => {
    return {
        type: actionType.SEND_SIMBA_STREAMING_REQUEST,
        payload
    }
}


export const sendMindMirrorStreamRequest = (payload: any) => {
    return {
        type: actionType.SEND_MIND_MIRROR_STREAM_REQUEST,
        payload
    }
}

export const startVilistusSimbaStreamRequest = (payload: any) => {
    return {
        type: actionType.START_VILISTUS_SIMBA_REQUEST,
        payload
    }
}
export const stopVilistusStreamRequest = (payload: any) => {
    return {
        type: actionType.STOP_VILISTUS_STREAM_REQUEST,
        payload
    }
}
export const sendVilistusSignalRequest = (payload: any) => {
    return {
        type: actionType.SEND_VILSITUS_SIGNAL_REQUEST,
        payload
    }
}