import { Observable, from, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import axios from "axios";
import mqtt from "mqtt";
import * as errorType from '../actions/actionTypes/errorTypes';
import * as errorCreater from '../actions/actionCreaters/errorCreater';
import * as actionType from "../actions/actionTypes/backendCommands";
// import * as actionCreater from "../actions/actionCreaters/signalCheck";
import * as actionCalibration from "../actions/actionCreaters/calibrateBaseline";
import * as simbaCreator from '../actions/actionCreaters/simbaReporting';

const baseUrl = process.env.REACT_APP_BASE_URL;
const mqttWsUrl = process.env.REACT_APP_MQTT_WS;


export const signalCheckRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_SIGNAL_CHECK_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/start_muse_signal_check/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const requestBaselineStream = (action$: any) => action$.pipe(
    ofType(actionType.SEND_BASELINE_STREAM_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/start_baseline_stream/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const requestBinoralEEGStream = (action$: any) => action$.pipe(
    ofType(actionType.SEND_BINORAL_EEG_STREAM_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/start_binoral_eeg/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const stopStreamRequest = (action$: any) => action$.pipe(
    ofType(actionType.STOP_STREAM_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/stop_stream/${action.payload.uid}/${action.payload.context}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const cancelStreamRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_CANCEL_STREAM_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/cancel_stream/${action.payload.uid}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );



// Converting This API INTO Create SIMBA REPORT
export const sendCalibrateBaselineRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_CALIBRATE_BASELINE_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "post",
          url: `${baseUrl}/create_new_simba_report`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          },
          data: {
            device: action.payload.device,
            protocol: action.payload.protocol,
            levels: action.payload.levels,
            length: action.payload.sessionLength,
            uid: action.payload.uid
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {
        return simbaCreator.mapSimbaReport(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const sendSimbaStreamingRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_SIMBA_STREAMING_REQUEST),
    switchMap((action: any ) => {
        return from(
        axios({
          method: "get",
          url: `${baseUrl}/start_simba_meditation/${action.payload.uid}/${action.payload.simbaProtocol}`,
          headers: {
            // Authorization: `Bearer ${action.payload.token}`,
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )}
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const sendMindMirrorStreamingRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_MIND_MIRROR_STREAM_REQUEST),
    switchMap((action: any ) => {
      
        return from(
        axios({
          method: "get",
          url: `${baseUrl}/mm_start_meditation/${action.payload.uid}`,
          headers: {
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )}
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const stopVilistusStreamingRequest = (action$: any) => action$.pipe(
    ofType(actionType.STOP_VILISTUS_STREAM_REQUEST),
    switchMap((action: any ) => {
      
        return from(
        axios({
          method: "get",
          url: `${baseUrl}/stop_vilitus_stream_request/${action.payload.uid}`,
          headers: {
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )}
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );


export const startVilistusSimbaStreamRequest = (action$: any) => action$.pipe(
    ofType(actionType.START_VILISTUS_SIMBA_REQUEST),
    switchMap((action: any ) => {
      
        return from(
        axios({
          method: "get",
          url: `${baseUrl}/start_vilistus_simba_stream_request/${action.payload.uid}/${action.payload.simbaProtocol}`,
          headers: {
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )}
    ),
    map((payload: any) => {
        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );

export const signalVilistusCheckRequest = (action$: any) => action$.pipe(
    ofType(actionType.SEND_VILSITUS_SIGNAL_REQUEST),
    switchMap((action: any ) =>
      from(
        axios({
          method: "get",
          url: `${baseUrl}/start_vilistus_signal_request/${action.payload.uid}`,
          headers: {
            "Content-Type": "application/json",
          }
        }).then((result) => result.data)
      )
    ),
    map((payload: any) => {

        return errorCreater.consoleLogHttp(payload);
    }),
    catchError((error) => {
      return of({
      type: errorType.ADD_ERROR_MESSAGE,
      payload: error.response.data,
      error: true
    })})
  );
