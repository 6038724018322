import * as actionTypes from '../actionTypes/simba';

export const setSimbaStreamType = (payload: any) => {
    return {
        type: actionTypes.SET_SIMBA_CONTEXT,
        payload
    }
}

export const setSimbaSessionLength = (payload: any) => {

    return {
        type: actionTypes.SET_SIMBA_LENGTH,
        payload
    }
}

export const updateLiveSimbaValues = (payload: any) => {
    return {
        type: actionTypes.UPDATE_LIVE_SIMBA_VALUES,
        payload
    }
}

export const liveSessionStatus = (payload: any) => {
    return {
        type: actionTypes.LIVE_SESSION_STATUS,
        payload
    }
}

