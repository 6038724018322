import * as actionType from '../actionTypes/simbaReporting';

export const setBeforeBaselineValues = (payload: any) => ({
    type: actionType.SET_BEFORE_BASELINE_VALUES,
    payload
})

export const setAfterBaselineValues = (payload: any) => ({
    type: actionType.SET_AFTER_BASELINE_VALUES,
    payload
})

export const setAverageLevel = (payload: any) => ({
    type: actionType.SET_AVERAGE_LEVEL,
    payload
})

export const createNewSimbaReport = (payload: any) => ({
    type: actionType.CREATE_NEW_SIMBA_REPORT,
    payload
})




export const mapSimbaReport = (payload: any) => ({
    type: actionType.MAP_SIMBA_REPORT,
    payload
})


export const mapAllSimbaReports = (payload: any) => ({
    type: actionType.MAP_ALL_SIMBA_REPORTS,
    payload
})