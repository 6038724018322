import React from 'react'
import SmallContainer from '../components/containers/SmallContainer'
import CardContainer from '../components/containers/CardContainer'
import BlsSetup from '../containers/MindMirrorSetup/serialPortSetup'
import StartMindMirror from '../containers/MindMirrorSetup/startMindMirror'
import styled from '@emotion/styled'

const Padding = styled('div')(() =>({
    padding: 30
}))

export default function ConnectVilistus() {
  return (
    <>
        <div style={{
            maxWidth: 600,
            margin: "70px auto 0px auto"
        }}>
    
            <CardContainer>
                <Padding>
                    <h1>Connect BLS</h1>
                    <h2>Step 1:</h2>
                    <p>Plug your BLS into your computer via usb or usb-c</p>
                    <h2>Step 2:</h2>
                    <p>Turn on your BLS</p>
                    <h2>Step 3:</h2>
                    <p>Press REQUEST CONNECTION - a box will appear from your browser, select the item that contains the words FT232R USB UART and press connect.</p>
                    <BlsSetup/>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
