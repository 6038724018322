import { SensorsOffSharp } from "@mui/icons-material";
import React from "react";
import { AnyAction } from "redux";
// import { ConnectableObservable } from "rxjs";
import * as actionType from '../../actions/actionTypes/calibrateBaseline';
import { RootState } from "../../store";
import { updateObj } from '../../utilities';



export interface CalibrateBaselineState {
  meanData: number[]
  start: number[],
  end: number[],
  
}

let intialState:  CalibrateBaselineState = {
  meanData: [],
  start: [],
  end: []
}

const liveTherapyBaseline = (
    state:  CalibrateBaselineState = intialState, 
    action: AnyAction
):  CalibrateBaselineState => {
    switch(action.type) {
        case actionType.MAP_BASELINE_CALIBRATION_DATA : return mapBaselineCalibrationData(state, action.payload);
        case actionType.RESET_CALIBRATION_BASELINE : return updateObj(state, {  
          meanData: [],
          start: [],
          end: []
        });
        default:
            return state;
    }
}

const mapBaselineCalibrationData = (state: CalibrateBaselineState, payload: any) => {

    return state
}

export default liveTherapyBaseline;