import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../store/store'
import { UserForm } from '../../interfaces/user';
import { stopVilistusStreamRequest } from '../../store/actions/actionCreaters/backendCommands';
import CardContainer from '../../components/containers/CardContainer';
import { liveSessionStatus } from '../../store/actions/actionCreaters/simba';
import styled from '@emotion/styled'
import DiscreteSlider from '../../components/sliders/sliders';
import { black } from '../../assets/colors';
import { convertHexStringToDecimalArray, decimalToHex, generateHexAndDecimalColorValues, toHex } from './helpers/asciiToDecimal';
import PrimaryButton from '../../components/buttons/PrimaryButton';

interface BlsWebType {
  writerPort: any
}

export const Blslive = ({
    writerPort
}: BlsWebType) => {

  // const [blsCommand, setBlsCommand] = useState<number[]>([
  //   74,65,48,48,67,56,48,48,48,48,48,48,48,48,48,48,48,48,48,70,70,70,70,70,70,48,48,48,48,48,48,48,48,48,48,48,48,48,49,49,50,14,10
  // ])



  const [blsCommand, setBlsCommand] = useState<number[]>([2, 74, 50, 48, 48, 54, 56, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 57, 54, 48, 48, 57, 54, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 52, 48, 51, 14, 10])

  const [color, setColor] = useState(5)
  const [intensity, setIntesity] = useState(150)
  const [start, setStart] = useState(false)

  useEffect(() => {
    console.log(decimalToHex(blsCommand))
    if(writerPort && start) {
      console.log('start')
      sendCommandToDevice()
    } else if(writerPort && !start) {
      console.log('stop')
      stopDevice()
    }
  }, [blsCommand, start])

  function blsColorPickerCommend(colour: number, colourIntensity: number) {
    let decimalColor = generateHexAndDecimalColorValues(colour, colourIntensity)
    console.log(decimalColor)
    let newCommand = [...blsCommand]
    decimalColor.decimal.forEach((number: number, i: number) => {
      newCommand[i + 19] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperSpeed(speed: number) {
    let tapperSpeed = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperSpeed)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 5] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperIntesity(speed: number) {
    let tapperIntensity = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperIntensity)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 37] = number
    })
    setBlsCommand(newCommand)
  }

  function blsSetTapperFrequency(speed: number) {
    let tapperIntensity = toHex(speed)
    let deciamalArray = convertHexStringToDecimalArray(tapperIntensity)
    let newCommand = [...blsCommand]
    deciamalArray.forEach((number: number, i: number) => {
      newCommand[i + 39] = number
    })
    setBlsCommand(newCommand)
  }

  function startBls() {
    let deciamalArray = convertHexStringToDecimalArray('JA')
    let newCommand = [...blsCommand]
    newCommand[1] = deciamalArray[0]
    newCommand[2] = deciamalArray[1]
    setBlsCommand(newCommand)
    setStart(true)
  }

  function stopBls() {
    // let deciamalArray = convertHexStringToDecimalArray('J2')
    // console.log(deciamalArray)
    // let newCommand = [...blsCommand]
    // newCommand[1] = deciamalArray[0]
    // newCommand[2] = deciamalArray[1]

    // // [2,74,50,13,10]
    // setBlsCommand(newCommand)
    setStart(false)
  }

  async function sendCommandToDevice() {
      try {
          let command = new Uint8Array(blsCommand.length)
          blsCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  async function stopDevice() {
      try {
          let stopCommand = [2,74,50,13,10]
          let command = new Uint8Array(stopCommand.length)
          stopCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  //TODO: Change opacity of buttons id not being used
  let colors = ['red', 'lightgreen', 'blue', 'yellow', 'purple', 'lightblue', 'white', 'black']
  let colorButtons = colors.map((colour: string, i: number) => {
    return <ColorButton 
        key={i}
        color={colour}
        onClick={() => {
          blsColorPickerCommend(i, intensity)
          setColor(i)
        }}
        focus={color === i}
    />
  })

  return (
    <div style={{
      maxWidth: 600,
      margin: "70px auto 0px auto"
  }}>
    <CardContainer>
      <Padding>
        <h1>BLS Controls</h1>
        <h2>Sprite/Tapper Speed</h2>
        <DiscreteSlider
          default={150}
          step={1}
          min={0}
          max={254}
          changed={(_: any, newValue:  number) => {
            blsSetTapperSpeed(254 - newValue)
          }}
          marks={false}
        />
        <h2>Color Intensity</h2>
        <DiscreteSlider
          default={150}
          step={1}
          min={0}
          max={255}
          changed={(_: any, newValue:  number) => {
            blsColorPickerCommend(color, newValue)
            setIntesity(newValue)
          }}
          marks={false}
        />
        <h2>Tapper Intensity</h2>
        <DiscreteSlider
          default={4}
          step={1}
          min={0}
          max={8}
          changed={(_: any, newValue:  number) => {
            blsSetTapperIntesity(newValue)
          }}
          marks={true}
        />
        <h2>Tapper Frequency</h2>
        <DiscreteSlider
          default={3}
          step={1}
          min={0}
          max={6}
          changed={(_: any, newValue:  number) => {
            blsSetTapperFrequency(newValue)
          }}
          marks={true}
        />
        <h2>Colors</h2>
        <ColorButtonContainer>
            {colorButtons}
        </ColorButtonContainer>
        <br/>
        <PrimaryButton clicked={() => {
          startBls()
        }}>Start</PrimaryButton>
        <PrimaryButton clicked={() => {
          stopBls()
        }}>Stop</PrimaryButton>
      </Padding>
    </CardContainer>
  </div>
  )
}

const mapStateToProps = (state: RootState) => ({
    writerPort: state.mindMirror.writerPort
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Blslive)

const Padding = styled('div')(() =>({
  padding: 30
}))

const ColorButton = styled('button')((props: any) => ({
  cursor: 'pointer',
  padding: '15px 25px',
  backgroundColor: props.color,
  borderRadius: 5,
  opacity:props.focus ? 1 : .5,
  border: `
    ${props.focus ? '4px':'2px'} 
    solid 
    ${black(.3)}
  `,
  
}))

const ColorButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

// function sendChangedValueToMindstreams() {
//       // sendVilistusData(value, userProfile.uid)
// }


//JA00aa 000000000000 bbccdd 000000000000 eeff
//J20068 000000000000 ab00ab 000000000000 0403
//J20099 000000000000 0000d8 000000000000 0501
//JA0068 000000000000 960096 000000000000 0403

