import * as actionType from '../actionTypes/binoralEEG';

export const openBinoralEEGWebsocket = (payload: any) => {
    return {
        type: actionType.OPEN_BINORAL_EEG_WEBSOCKET,
        payload
    }
}

export const mapBinoralEEGnData = (payload: any) => {
    return {
        type: actionType.MAP_BINORAL_EEG_DATA,
        payload
    }
}